import { buildArticleLink } from '@utils/link';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import { FunctionComponent } from 'react';
import { Heading } from '../shared/Heading';
import { Container, Section } from './HotStories.styles';
import { HotStoriesProps } from './HotStories.types';

const ArticleCard = dynamic(
  () => import('@components/common/Card/ArticleCard').then((mod) => mod.ArticleCard),
  { ssr: true },
);

export const HotStories: FunctionComponent<HotStoriesProps> = ({ articles }) => {
  const { t } = useTranslation('home');

  return (
    <Section noBorder>
      <Heading>{t('hot')}</Heading>
      <Container thinned>
        {articles[0] && (
          <ArticleCard
            href={buildArticleLink(articles[0].id, articles[0].slug)}
            article={articles[0]}
            withDescription={false}
            thumbnail="top"
            variant="medium"
            size="medium"
          />
        )}
        {articles[1] && (
          <ArticleCard
            href={buildArticleLink(articles[1].id, articles[1].slug)}
            article={articles[1]}
            withDescription={false}
            thumbnail="none"
            variant="small"
          />
        )}
        {articles[2] && (
          <ArticleCard
            href={buildArticleLink(articles[2].id, articles[2].slug)}
            article={articles[2]}
            withDescription={false}
            thumbnail="none"
            variant="small"
          />
        )}
      </Container>
    </Section>
  );
};
