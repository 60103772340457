import tw, { styled } from 'twin.macro';
import { Section as SectionBase } from '../shared/Section/Section.styles';

export const Section = styled(SectionBase)`
  ${tw`col-span-3`};
`;

export const Container = styled.div`
  ${tw`flex flex-grow grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-10`};
`;
