import styled from 'styled-components';
import tw from 'twin.macro';

export const Container = styled.div`
  .collection-card{
    &:not(:first-child){
      ${tw`border-t border-dashed`};
      border-color: ${({ theme }) => theme.colors.title};
      margin: 24px 0 0 0;
      padding: 24px 0 0 0;
    }
  }
`;
