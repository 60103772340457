import { Article } from '@api/api';
import { buildArticleLink, buildCategoryPageLink } from '@utils/link';
import useTranslation from 'next-translate/useTranslation';
import { FunctionComponent } from 'react';
import tw from 'twin.macro';

import { ArrowRight } from '@components/common/Button/styles';
import { StyledH2 } from '@components/common/Card/ArticleCard/ArticleCard.styles';
import { P } from '@components/common/Card/shared/Footer/Footer.styles';
import { NextImage } from '@components/nextImage/NextImage';
import { getArticleDates } from '@utils/getArticleDates';
import NextLink from 'next/link';
import { Heading } from '../shared/Heading';
import { Section } from '../shared/Section/Section.styles';

interface ArticlesCardProps {
  articles: Article[];
  heading: string;
  readMore?: boolean;
}

interface ArticlesCardListProps {
  articles: Article[];
}

interface ArticleCardProps {
  title: string;
  authorName: string;
  publishedAt: string;
  href: string;
  thumbnailUrl;
}

const ArticleCard: FunctionComponent<ArticleCardProps> = ({
  title,
  authorName,
  publishedAt,
  thumbnailUrl,
  href,
}) => {
  return (
    <NextLink passHref href={href}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a>
        <div css={tw`flex gap-4`}>
          <div style={{ minWidth: 120, minHeight: 70 }}>
            <NextImage
              src={thumbnailUrl}
              width={120}
              height={70}
              objectFit="contain"
              objectPosition="top"
            />
          </div>
          <div css={tw`flex-grow`}>
            <div css={tw`flex justify-between`}>
              <P variant="small" isBold>
                {authorName}
              </P>
              <P variant="small">{publishedAt}</P>
            </div>
            <StyledH2 css={tw`text-base leading-4`} as="h3" style={{ display: 'inline' }}>
              {title}
            </StyledH2>
          </div>
        </div>
      </a>
    </NextLink>
  );
};

const ArticlesCardList: FunctionComponent<ArticlesCardListProps> = ({ articles }) => {
  return (
    <div css={tw`flex flex-col gap-6`}>
      {articles.map((article) => (
        <ArticleCard
          key={article.id}
          href={buildArticleLink(article.id, article.slug)}
          authorName={article.author?.name}
          title={article.title}
          publishedAt={
            getArticleDates({
              publishedAt: article.publishedAt,
              updatedAt: article.updatedAt,
              publishedDate: article.publishedDate,
              dateFormat: 'MMMM d, yyyy',
            }).publishedAt
          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          thumbnailUrl={article.thumbnail?.hash}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          alt={article.thumbnail?.caption}
        />
      ))}
    </div>
  );
};

export const ArticlesCard: FunctionComponent<ArticlesCardProps> = ({
  articles,
  heading,
  readMore = true,
}) => {
  const { t } = useTranslation('home');

  return (
    <Section css={tw`mb-10`}>
      <Heading>{heading}</Heading>
      <ArticlesCardList articles={articles} />
      {readMore && (
        <div css={tw`flex items-center gap-4 mt-6`}>
          <div css={tw`border-b border-black border-dashed flex-grow h-0`} />
          <NextLink passHref href={buildCategoryPageLink('press-release')}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a css={tw`text-sm font-inter font-semibold`}>
              {t('read-more')} <ArrowRight css={tw`inline`} />
            </a>
          </NextLink>
        </div>
      )}
    </Section>
  );
};

ArticlesCard.defaultProps = {
  readMore: true,
};
