import { Article } from '@api/api';
import { FunctionComponent } from 'react';
// import { ArticleCard } from '@components/common/Card/ArticleCard';
import { buildArticleLink } from '@utils/link';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import { Heading } from '../shared/Heading';
import { Section } from '../shared/Section';
import { Container } from './TopArticles.styles';
import { TopArticlesProps } from './TopArticles.types';

const ArticleCard = dynamic(
  () => import('@components/common/Card/ArticleCard').then((mod) => mod.ArticleCard),
  { ssr: true },
);

const Articles: FunctionComponent<{ articles: Article[] }> = ({ articles }) => {
  return (
    <Container>
      {articles.map((article: Article) => (
        <ArticleCard
          key={article.id}
          href={buildArticleLink(article.id, article.slug)}
          article={article}
          withDescription={false}
          thumbnail="left"
          variant="small"
          size="tiny"
        />
      ))}
    </Container>
  );
};

export const TopArticles: FunctionComponent<TopArticlesProps> = ({ subcategory, articles }) => {
  const { t } = useTranslation('home');

  return (
    <Section>
      <Heading>{t('top-articles', { subcategoryName: subcategory.name })}</Heading>
      <Articles articles={articles} />
    </Section>
  );
};
