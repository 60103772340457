import tw, { styled } from 'twin.macro';
import { Label, MEDIA_QUERIES } from '@student-coin/coinpaper-components';
import SliderIcon from '@components/icons/sliderIcon.svg';
import { Section as SectionBase } from '../shared/Section';

export const Row = styled.div`
  ${tw`flex justify-between content-center`};

  ${Label} {
    ${tw`pb-4 sm:pb-6`};
  }
`;

export const Section = styled(SectionBase)`
  ${tw`col-span-3 md:col-span-2 flex flex-col`};

  .BrainhubCarousel__track{
    overflow: unset!important;
  }

  li.BrainhubCarouselItem {
    height: 100%;
  }
`;

export const Container = styled.div`
  ${tw`flex flex-grow grid grid-cols-1 gap-10`};

  @media ${MEDIA_QUERIES.tablet} {
    ${tw`grid-cols-2`};
  }
`;

export const DotIcon = styled(SliderIcon)<{ disabled: boolean }>`
  fill-opacity: ${({ disabled }) => (disabled ? 1 : 0.3)};
`;

export const Slider = styled.div`
  ${tw`flex flex-row-reverse items-center`};
  padding-bottom: 1rem;

  svg:hover {
    cursor: pointer;
  }

  svg:first-of-type {
    margin-left: 0.75rem;
  }

  @media ${MEDIA_QUERIES.tablet} {
    ${tw`hidden`};
  }
`;
