import styled from 'styled-components';
import { Container as ArticleCard } from '@components/common/Card/ArticleCard/ArticleCard.styles';

export const Container = styled.div<{ thinned?: boolean }>`
  ${ArticleCard} {
    :not(:first-child) {
      padding-top: ${({ thinned }) => (thinned ? '1rem' : '1.5rem')};
    }

    :not(:last-child) {
      padding-bottom: ${({ thinned }) => (thinned ? '1rem' : '1.5rem')};
      border-bottom: 1px dashed #000000;
    }
  }
`;
