import { HomepageAllData } from '@api/api';
import { homepageApiClient } from '@api/clients';
import { useQuery, UseQueryResult } from 'react-query';

export const fetchHomepage = async (): Promise<HomepageAllData> => {
  const { data } = await homepageApiClient.homepageAllDataGet();
  return data;
};

export const useHomepage = (): UseQueryResult<HomepageAllData> => {
  return useQuery('homepage', fetchHomepage);
};
