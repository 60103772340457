import Head from 'next/head';

export const HomepageJsonLd: React.FC = () => {
  const jsonLdData = {
    '@context': 'https://schema.org',
    '@type': 'NewsMediaOrganization',
    name: 'Coinpaper',
    description:
      'Latest news about crypto industry, every day on Coinpaper. Read about Bitcoin, Ethereum, Research & Analysis, and more.',
    url: 'https://coinpaper.com',
    logo: 'https://coinpaper.com/img/logo.png',
    foundingDate: 2021,
    publisher: 'Coinpaper',
    sameAs: [
      'https://instagram.com/coinpapercom',
      'https://twitter.com/coinpapercom',
      'https://facebook.com/coinpapercom',
      'https://www.linkedin.com/company/coinpaper-com/',
      'https://coinmarketcap.com/community/profile/Coinpaper/',
    ],
  };

  return (
    <Head>
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdData) }}
      />
    </Head>
  );
};
