
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { AdContainer } from '@components/common/AdContainer/AdContainer';
import { HomeHeading } from '@components/common/Heading/Heading';
import { Seo } from '@components/common/Seo';
import { HomepageJsonLd } from '@components/common/Seo/JsonLd/HomepageJsonLd';
import { ArticlesCard } from '@components/homepage/ArticlesCard/ArticlesCard';
import { Collections } from '@components/homepage/Collections';
import { EditorPicks } from '@components/homepage/EditorPicks';
import { FeaturedStories } from '@components/homepage/FeaturedStories';
import { HotStories } from '@components/homepage/HotStories';
import { LatestStories } from '@components/homepage/LatestStories';
import { TopArticles } from '@components/homepage/TopArticles';
import { queries } from '@consts/queries';
import { fetchAdPlacements } from '@queries/useAdPlacements';
import { fetchArticlesCount } from '@queries/useArticlesCount';
import { fetchEvergreen } from '@queries/useEvergreen';
import { fetchHomepage, useHomepage } from '@queries/useHomepage';
import { fetchLatestStories } from '@queries/useLatestStories';
import { fetchTopReads } from '@queries/useTopReads';
import { buildHomepageLink } from '@utils/link';
import { withGlobalSettings } from '@utils/pageFetchEnhancer';
import { buildOpenGraph, buildResourceSeoSettings } from '@utils/seo';
import { GetStaticProps } from 'next';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dist/shared/lib/dynamic';
import { ReactElement } from 'react';
import { dehydrate } from 'react-query';
import tw from 'twin.macro';
import { PageProps } from '../types/page';

const AdPlacementComponent = dynamic(
  () => import('@components/common/AdPlacement').then((mod) => mod.AdPlacement),
  { ssr: false },
);

export default function Home({ seo, ads, topReads, evergreen }): ReactElement {
  const { data: homepageData } = useHomepage();

  const {
    homepage = { collections: [] },
    featuredArticles = [],
    editorsPicksArticles = [],
    hotArticles = [],
    topSubcategories = [],
    pressReleases = [],
  } = homepageData || {};

  const { t } = useTranslation('home');
  const url = buildHomepageLink();

  return (
    <>
      <Seo
        canonical={url}
        metaTitle={seo.metaTitle}
        metaDescription={seo.metaDescription}
        openGraph={buildOpenGraph({
          title: seo.shareTitle,
          description: seo.shareDescription,
          type: 'website',
          sitename: seo.siteName,
          url,
          image: {
            url: seo.shareImage?.url,
            width: seo.shareImage?.width,
            height: seo.shareImage?.height,
            alt: seo.shareImage?.alternativeText,
          },
        })}
      />
      <HomepageJsonLd />
      <HomeHeading />
      <div css={tw`grid grid-cols-3 gap-10`}>
        <FeaturedStories articles={featuredArticles} />
        <HotStories articles={hotArticles} />
        <EditorPicks articles={editorsPicksArticles} />
        <LatestStories loadMoreText={t('load-more')} />
        <div css={tw`col-span-3 lg:col-span-1`}>
          {/* TODO: refactor to a component */}
          {topSubcategories.map(({ subcategory, articles }) => (
            <TopArticles key={subcategory.id} subcategory={subcategory} articles={articles} />
          ))}
          <ArticlesCard articles={pressReleases} heading={t('press-releases')} />
          <Collections collections={homepage.collections} />
          <ArticlesCard articles={topReads} heading={t('top-reads')} />
          <div css={tw`hidden lg:block border-t border-t-black border-dashed pt-4 mt-4 mb-8`}>
            <AdContainer adPlacements={ads} placement="homepage-desktop-vertical-banner">
              <AdPlacementComponent
                adPlacements={ads}
                placement="homepage-desktop-vertical-banner"
              />
            </AdContainer>
          </div>
          <ArticlesCard articles={evergreen} heading={t('learn-today')} readMore={false} />
        </div>
      </div>
    </>
  );
}

 const _getStaticProps: GetStaticProps = withGlobalSettings<PageProps>(
  async (nextContext, { queryClient, globalSettingsPromise, adScriptsPromise }) => {
    const [homepageAllData, topReads, evergreen, globalSettings, ads, adScripts] =
      await Promise.all([
        queryClient.fetchQuery('homepage', () => fetchHomepage()),
        queryClient.fetchQuery('top-reads', () => fetchTopReads(6)),
        queryClient.fetchQuery('evergreen', () => fetchEvergreen(6)),
        globalSettingsPromise,
        queryClient.fetchQuery('ad', () =>
          fetchAdPlacements({
            placement: 'homepage-desktop-vertical-banner',
            is_active: true,
          }),
        ),
        adScriptsPromise,
        queryClient.prefetchQuery(['articles-count', queries.latestStories], () =>
          fetchArticlesCount(queries.latestStories),
        ),
        queryClient.prefetchInfiniteQuery('latest-stories', () =>
          fetchLatestStories({ pageParam: 0 }),
        ),
      ]);

    return {
      props: {
        dehydratedState: JSON.parse(JSON.stringify(dehydrate(queryClient))),
        showCoinPriceSlider: true,
        showNewsletter: true,
        seo: JSON.parse(
          JSON.stringify(
            buildResourceSeoSettings(
              {
                ...globalSettings.global.seo,
                siteName: globalSettings.global.siteName,
              },
              homepageAllData.homepage.seo,
            ),
          ),
        ),
        ads,
        topReads,
        evergreen,
        adScripts,
        globalSettings,
      },
      revalidate: 600,
    };
  },
);


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  