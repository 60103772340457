import { MEDIA_QUERIES } from '@student-coin/coinpaper-components';
import styled from 'styled-components';

export const Container = styled.div<{ heightPc: number | null; heightMobile: number | null }>`
  min-height: ${({ heightMobile }) =>
    heightMobile !== null && heightMobile !== undefined && `${heightMobile}px !important`};
  @media ${MEDIA_QUERIES.tablet} {
    min-height: ${({ heightPc }) =>
      heightPc !== null && heightPc !== undefined && `${heightPc}px !important`};
  }
`;

export const SponsoredText = styled.span`
  display: inline-block;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.label};
  line-height: 130%;
  margin-bottom: 8px;
`;
