import { Article } from '@api/api';
import { ArticleCard } from '@components/common/Card/ArticleCard';
import { buildArticleLink } from '@utils/link';
import useTranslation from 'next-translate/useTranslation';
import { FunctionComponent } from 'react';
import { Heading } from '../shared/Heading';
import { Container, Row, Section } from './FeaturedStories.styles';
import { FeaturedStoriesProps } from './FeaturedStories.types';

export const FeaturedStories: FunctionComponent<FeaturedStoriesProps> = ({ articles }) => {
  const { t } = useTranslation('home');

  return (
    <Section noBorder>
      <Row>
        <Heading>{t('featured')}</Heading>
      </Row>
      <Container>
        {articles.map((article: Article) => (
          <ArticleCard
            key={article.id}
            href={buildArticleLink(article.id, article.slug)}
            article={article}
            thumbnail="top"
            variant="medium"
            size="mediumXx"
          />
        ))}
      </Container>
    </Section>
  );
};
