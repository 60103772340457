import { Collection } from '@api/api';
import { buildCollectionPageLink } from '@utils/link';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import { FunctionComponent } from 'react';
import tw from 'twin.macro';
import { Heading } from '../shared/Heading';
import { Section } from '../shared/Section/Section.styles';
import { Container } from './Collections.styles';
import { CollectionsProps } from './Collections.types';

const CollectionCard = dynamic(
  () =>
    import('@components/common/Card/CollectionCard').then<
      typeof import('@components/common/Card/CollectionCard').CollectionCard
    >((mod) => mod.CollectionCard),
  { ssr: true },
);

const CollectionList: FunctionComponent<CollectionsProps> = ({ collections }) => {
  return (
    <Container>
      {collections.map((collection: Collection) => (
        <CollectionCard
          key={collection.id}
          href={buildCollectionPageLink(collection.slug)}
          collection={collection}
          size="mediumX"
        />
      ))}
    </Container>
  );
};

export const Collections: FunctionComponent<CollectionsProps> = ({ collections }) => {
  const { t } = useTranslation('home');

  return (
    <Section css={tw`mb-10`}>
      <Heading>{t('collections')}</Heading>
      <CollectionList collections={collections} />
    </Section>
  );
};
