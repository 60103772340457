import { isProduction } from '@utils/isProduction';
import { twitterCard } from '@utils/seo';
import { NextSeo } from 'next-seo';
import { FC } from 'react';
import { SeoProps } from './Seo.types';

export const Seo: FC<SeoProps> = ({
  siteName,
  metaDescription,
  metaTitle,
  openGraph,
  canonical,
  twitter = twitterCard,
  titleTemplate = `%s ${siteName ? `- ${siteName}` : ''}`,
  noindex,
  nofollow,
}) => {
  return (
    <NextSeo
      noindex={!isProduction() || noindex}
      nofollow={!isProduction() || nofollow}
      robotsProps={{
        maxImagePreview: 'large',
      }}
      canonical={canonical}
      titleTemplate={titleTemplate}
      title={metaTitle}
      description={metaDescription}
      openGraph={openGraph}
      twitter={twitter}
    />
  );
};
