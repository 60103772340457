import { Article } from '@api/api';
import { FunctionComponent } from 'react';
// import { ArticleCard } from '@components/common/Card/ArticleCard';
import { buildArticleLink } from '@utils/link';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import { Heading } from '../shared/Heading';
import { Container, Section } from './EditorPicks.styles';
import { EditorPicksProps } from './EditorPicks.types';

const ArticleCard = dynamic(
  () => import('@components/common/Card/ArticleCard').then((mod) => mod.ArticleCard),
  { ssr: true },
);

const Articles: FunctionComponent<EditorPicksProps> = ({ articles }) => {
  return (
    <Container>
      {articles.map((article: Article) => (
        <ArticleCard
          key={article.id}
          href={buildArticleLink(article.id, article.slug)}
          article={article}
          thumbnail="top"
          variant="small"
          withDescription={false}
          displayPublishedAt={false}
        />
      ))}
    </Container>
  );
};

export const EditorPicks: FunctionComponent<EditorPicksProps> = ({ articles }) => {
  const { t } = useTranslation('home');

  return (
    <Section>
      <Heading>{t('editors-picks')}</Heading>
      <Articles articles={articles} />
    </Section>
  );
};
