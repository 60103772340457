import useTranslation from 'next-translate/useTranslation';
import { FC } from 'react';
import { Container, SponsoredText } from './AdContainer.styles';
import { AdContainerProps } from './AdContainer.types';

export const AdContainer: FC<AdContainerProps> = ({
  children,
  adPlacements,
  placement,
  sponsoredText = false,
}) => {
  const { t } = useTranslation('common');
  const ad = adPlacements.find((adPlacement) => adPlacement.placement === placement);

  if (!ad) return null;

  const { heightPc, heightMobile } = ad;

  return (
    <Container heightMobile={heightMobile} heightPc={heightPc}>
      {sponsoredText && <SponsoredText>{t('sponsored')}</SponsoredText>}
      {children}
    </Container>
  );
};
